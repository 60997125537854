import React, { useState } from "react";
import AuctionCard from "../../../containers/AuctionCards/AuctionCards";
import "./Hero.css";
import { MaskGroup109, Group211, pin } from "../../../assets/Auction/index";
import Insight from "../../../containers/Insight/Insight";
import locationicon from "../../../assets/Auction/locationicon.png";

const cardData = [
  {
    image: MaskGroup109,
    description: "1400 SQFT",
    date: "10 July - 30 July",
    location: "Gachibowli HYD",
    amount: "₹80,000",
  },
  {
    image: MaskGroup109,
    description: "2200 SQFT",
    date: "10 July - 30 July",
    location: "Gachibowli HYD",
    amount: "₹1,00,000",
  },
  {
    image: MaskGroup109,
    description: "2200 SQFT",
    date: "10 July - 30 July",
    location: "Gachibowli HYD",
    amount: "₹1,00,000",
  },
  {
    image: MaskGroup109,
    description: "2200 SQFT",
    date: "10 July - 30 July",
    location: "Gachibowli HYD",
    amount: "₹1,00,000",
  },

  // Add more card data objects here
];
const Hero = () => {
  const [detailsVisible, setDetailsVisible] = useState(false);

  const toggleDetails = () => {
    setDetailsVisible(!detailsVisible);
  };

  return (
    <div>
      <div className="auction-hero-main-container section__padding">
        <div className="cards-section">
          {cardData.map((card, index) => (
            <AuctionCard
              key={index}
              image={card.image}
              description={card.description}
              date={card.date}
              location={card.location}
              amount={card.amount}
            />
          ))}
        </div>
        <div>
          <div className="auction-hero-img">
            {detailsVisible && (
              <div className="details-container">
                <div className="map-details-container">
                  <div>
                    <img src={MaskGroup109} alt="" />
                  </div>
                  <div>
                    <div className="map-details-container-sub">
                      <h2 className="map-sub-heading">1400 SQFT</h2>
                      <p className="auction-card-container-date">
                        10 July - 30 July
                      </p>
                    </div>
                    <div className="auction-map-container-sub">
                      <div className="auction-map-container-sub-img">
                        <img src={locationicon} alt="" />
                      </div>
                      <p>Gachibowli HYD</p>
                    </div>
                    <div className="map-details-container-sub">
                      <h3 className="auctuion-card-amount">₹80,000</h3>
                      <p className="auctuion-map-bidding-text ">Biding base</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <img
              className="auction-hero-main-img"
              src={Group211}
              alt="locations"
            />
            <div className="auction-map-pin">
              <img
                src={pin}
                className="pin-image"
                alt="location"
                onClick={toggleDetails}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="section__padding">
        <Insight />
      </div>
    </div>
  );
};

export default Hero;
