import React from "react";
import Hero from "../components/Buy/Hero/Hero";
import Banner from "../components/Buy/Banner/Banner";
import Hero2 from "../components/Buy/Hero2/Hero2";

const Auctions = () => {
  return (
    <div>
      <Banner />
      <Hero />
      <Hero2 />
    </div>
  );
};

export default Auctions;
