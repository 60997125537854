import React from "react";
import "./Hero3.css";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { MaskGroup15, MaskGroup16 } from "../../assets/Home";

const Hero3 = () => {
  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger the animation once
    threshold: 0.2, // The percentage of the section that should be visible to trigger the animation
  });

  return (
    <div className="">
      <div className="section3-container" ref={ref}>
        <div className="section3-image-container">
          <motion.img
            src={MaskGroup15}
            alt="Groupmask15"
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : -20 }}
            transition={{ duration: 0.8 }}
          />
        </div>
        <div className="section3-text-container">
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 20 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            Easier, Smarter, and Accessible to Everyone
          </motion.p>
          <motion.h1
            className="section3-text-container-heading"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 20 }}
            transition={{ duration: 0.8, delay: 0.4 }}
          >
            New Era Of Plot of Buying
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 20 }}
            transition={{ duration: 0.8, delay: 0.6 }}
          >
            Experience financial freedom with MySft's versatile loans and no
            usage restrictions. Achieve your goals, from home renovations to
            debt consolidation, with our flexible loan options.
          </motion.p>
          <motion.button
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 20 }}
            transition={{ duration: 0.8, delay: 0.8 }}
          >
            Buy now
          </motion.button>
        </div>
        <div className="section-right-image">
          <motion.img
            src={MaskGroup16}
            alt="Groupmask16"
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : 20 }}
            transition={{ duration: 0.8 }}
          />
        </div>
      </div>
    </div>
  );
};

export default Hero3;
