import React from "react";
import "./footer.css";
import Brand from "../../components/brand/Brand";
import { Group45, Group46, Group47 } from "../../assets/Home";
import { Link, useLocation } from "react-router-dom";

const Footer = () => (
  <div>
    <Brand />
    <div className="gpt3__footer section__padding">
      <div className="gpt3__footer-links">
        <div className="gpt3__footer-links_div">
          <h4>Home</h4>
          <p>Insights</p>
          <p>Crowd funding</p>
          <p>News</p>
          <p>Emi Calculator</p>
        </div>
        <div className="gpt3__footer-links_div">
          <h4>What we do</h4>
          <p>Buy </p>
          <p>List your property</p>
          <p>Legal</p>
          <p>loans</p>
          <p>Auctions</p>
        </div>
        <div className="gpt3__footer-links_div">
          <h4>About us</h4>
          <p>Careers</p>
          <p>Our team</p>
          <p>
            <Link to="/ContactUs">Contact</Link>
          </p>
        </div>
        <div className="gpt3__footer-links_div">
          <h4>Contact</h4>
          <p>hellomysft@gmail.com</p>
          <p>+91 8465487565</p>
          <p>Level 5, Patrika Nagar, Madhapur, Hyderabad, Telangana 500081</p>
        </div>
        <div className="gpt3__footer-links_logo-img">
          <h2>
            <Link to="/">MySft</Link>
          </h2>
          <div className="gpt3__footer-links_logo-img ">
            <img src={Group45} alt="facebook" />
            <img src={Group46} alt="lindin" />
            <img src={Group47} alt="twitter" />
          </div>
        </div>
        {/* <div className="gpt3__footer-links_logo">
          <div>
            <h4>MySft</h4>
          </div>
          <div className="gpt3__footer-links_logo-img">
            <img src={Group45} alt="facebook" />
            <img src={Group46} alt="lindin" />
            <img src={Group47} alt="twitter" />
          </div>
        </div> */}
      </div>

      <div className="gpt3__footer-copyright">
        <div>
          <p>© 2023 MySft rights reserved.</p>
        </div>
        <div className="gpt3__footer-copyright-2">
          <p>Privacy</p>
          <p>Terms</p>
          <p>Security</p>
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
