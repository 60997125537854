import React from "react";
import "./Banner.css";
import Navbar from "../../navbar/Navbar";
import HorizontalTabs from "../../../containers/HorizontalTabs/HorizontalTabs";

const Banner = () => {
    return (
      <div>
        <div className="">
          <div className="buy-banner-container-bg">
            <Navbar />
            <div className="buy-banner-container section__padding">
              <h1 className="buy-banner-main-heading">
                Properties to Buy in Hyderabad
              </h1>
              <HorizontalTabs />
            </div>
          </div>
        </div>
      </div>
    );
}
    
export default Banner;