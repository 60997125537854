import React from "react";
import "./ContactForm.css";

const ContactUs = () => {
  return (
    <div className="contact-section">
      <div className="contact-form">
        <div className="contact-header">
          <h2>Chat to our team</h2>
          <p>
            Need help with something? Get in touch with our team we'll get back
            soon
          </p>
        </div>
        <div className="form-group">
          <input type="text" placeholder="Name" />
        </div>
        <div className="form-group">
          <input type="tel" placeholder="Phone" />
        </div>
        <div className="form-group">
          <input type="email" placeholder="Email" />
        </div>
        <div className="form-group">
          <textarea placeholder="Message"></textarea>
        </div>
        <div className="role-group">
          <label>
            <input type="radio" name="role" value="buyer" />
            Buyer
          </label>
          <label>
            <input type="radio" name="role" value="seller" />
            Seller
          </label>
          <label>
            <input type="radio" name="role" value="agent" />
            Agent
          </label>
        </div>
        <button className="submit-button" type="submit">
          Contact us
        </button>
      </div>
    </div>
  );
};

export default ContactUs;
