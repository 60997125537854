import React from "react";
import "./Banner.css";
import Navbar from "../../navbar/Navbar";
import {location, mail, telephone,} from "../../../assets/Contact/index.js";
import ContactUs from "../../../containers/ContactForm/ContactForm";

const Banner = () => {
  return (
    <div className="contact-bg">
      <Navbar />

      <div className="contact-main-container section__padding">
        <div className="contact-section">
          <h1 className="contact-heading">Reach out us</h1>
          <div className="contact-details">
            <div className="contact-icon">
              <img src={mail} alt="Email" />
            </div>
            <div className="contact-info">
              <p>hello@mysft.com</p>
            </div>
          </div>
          <div className="contact-details">
            <div className="contact-icon">
              <img src={telephone} alt="Phone" />
            </div>
            <div className="contact-info">
              <p>040 4027 3049</p>
            </div>
          </div>
          <div className="contact-details">
            <div className="contact-icon">
              <img src={location} alt="Address" />
            </div>
            <div className="contact-info">
              <p>
                #501, Level 5, Plot No:57/99 & 50/109, Survey No: 78, Patrika
                Nagar, Madhapur, Hyderabad, Telangana 500081
              </p>
            </div>
          </div>
        </div>
        <div>
          <ContactUs />
        </div>
      </div>
    </div>
  );
};

export default Banner;
