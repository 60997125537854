// MainComponent.js
import React from "react";
import VerticalSlider from "../../containers/VerticalSlider/VerticalSlider";
import Hero from "../Hero/Hero";
import "./Slider.css"; // Import the CSS file

const Slider = () => {
  return (
    <div className="slider-container">
      <div className="hero">
        <Hero />
      </div>
      <div className="vertical-slider">
        <VerticalSlider />
      </div>
    </div>
  );
};

export default Slider;
