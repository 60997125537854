import React, { useState } from "react";
import "./Faq.css"; // Create a CSS file for styling
import Arrow from "../../assets/ARROW.png";

const faqData = [
  {
    question: "What is a personal loan?",
    answer: "Answer 1",
  },
  {
    question: "What is the interest rate for personal loan?",
    answer: "Answer 2",
  },
  {
    question: "What are the eligibility criteria?",
    answer: "Answer 2",
  },
  {
    question: "How can I apply for a personal loan?",
    answer: "Answer 2",
  },
  {
    question: "How long does it take to get a personal loan?",
    answer: "Answer 2",
  },
  // Add more questions and answers as needed
];

const FaqSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(null);
  };

  return (
    <div className="faq-section">
      {faqData.map((faqItem, index) => (
        <div
          key={index}
          className={`faq-item ${activeIndex === index ? "open" : ""}`}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
        >
          <div className="question">
            <p>{faqItem.question}</p>
            <span>
              <img
                className={`arrow ${activeIndex === index ? "open" : ""}`}
                src={Arrow}
                alt="arrow"
              />
            </span>
          </div>
          {activeIndex === index && (
            <div className="answer">{faqItem.answer}</div>
          )}
          <hr className="horizontal-line" />
        </div>
      ))}
    </div>
  );
};

export default FaqSection;
