import React from "react";
import { Footer } from "./containers";
import ContactUs from "./Pages/ContactUs";
import "./App.css"; // Path: src\App.css
import Home from "./Pages/Home";
import { Route, Routes, useLocation } from "react-router-dom";
import Buy from "./Pages/Buy";
import Crowd from "./Pages/Sell";
import Auctions from "./Pages/Auctions";
import Auction from "./Pages/Auction";

function ScrollToTop() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const App = () => {
  return (
    <div className="App">
      <div className="">
        <ScrollToTop /> {/* ScrollToTop component */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Sell" element={<Crowd />} />
          <Route path="/Buy" element={<Auctions />} />
          <Route path="/Crowd" element={<Buy />} />
          <Route path="/Auction" element={<Auction />} />
          <Route path="/ContactUs" element={<ContactUs />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

export default App;
