import React from 'react'
import './Hero5.css';
import StyledFAQ from '../../components/Faq/Faq';

const Hero5 = () => {
  return (
    <div className="section5-container section__padding">
      <div>
        <h1>
          From Consulation{" "}
          <span className="section5-heading-span">to Final Touch</span>
        </h1>
        <StyledFAQ />
      </div>
    </div>
  );
}

export default Hero5