import React, { useState } from "react";
import "./VerticalTabs2.css";
import {arrow} from '../../assets/Buy/index'

const tabsData = [
  {
    title: "Verified properties",
    content:
      "We offer verified properties, ensuring your investment is secure and hassle-free. Find your dream home with confidence, backed by our reliable verification process",
  },
  {
    title: "Hasslefree paperwork",
    content:
      "Say goodbye to hassles with our streamlined process. Let us handle the paperwork, ensuring a smooth and stress-free experience for your property transactions.",
  },
  {
    title: "Easy personal loan",
    content:
      "Our easy personal loans offer quick approval and flexible terms. Achieve your goals without stress, as we simplify the borrowing process for you",
  },
];

const VerticalTabs2 = () => {
  const [hoveredTab, setHoveredTab] = useState(null);

  return (
    <div className="verticaltabs2">
      <div className="tabs2-container ">
        <p>What we offer</p>
        {tabsData.map((tab, index) => (
          <div
            key={index}
            className={`tab2 ${hoveredTab === index ? "hovered" : ""}`}
            onMouseEnter={() => setHoveredTab(index)}
            onMouseLeave={() => setHoveredTab(null)}
          >
            <span className="tab-title2">{tab.title}</span>
            {hoveredTab === index ? (
              <span>
                <img className="tab-arrow2" src={arrow} alt="" />
              </span>
            ) : null}
          </div>
        ))}
      </div>
      <div className="tab-content2">
        {hoveredTab !== null ? tabsData[hoveredTab].content : ""}
      </div>
    </div>
  );
};

export default VerticalTabs2;
