import React from "react";
import FaqSection from "../../Faqs/Faqs";
import "./Hero.css";
import { Group212 } from "../../../../assets/Contact/index.js";

const Hero = () => {
  return (
    <div>
      <h1 className="faq-heading">Frequently Asked Questions</h1>
      <div className="contact-hero-main-container">
        <div className="contact-hero-main-container-faq">
          <FaqSection />
        </div>
        <div>
          <img
            className="contact-hero-main-container-img"
            src={Group212}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
