import React from "react";
import "./Hero4.css";
import Carousel from "../../../containers/carosuel/carousel";
import {Group170 } from "../../../assets/List/index";
const Hero4 = () => {
    return (
      <div className="list-hero4-container ">
        <h1>
          Event organizers enjoy adavanced control with the MySft Total Event
          control platfrom
        </h1>
        <div className="list4-hero4-container-main">
          <div>
            <Carousel />
          </div>
          <div>
            <img
              className="list4-hero4-container-main-img"
              src={Group170}
              alt="logo"
            />
          </div>
        </div>
      </div>
    );
}
    
export default Hero4;