import React from "react";
import Banner from "../components/Auctions/Banner/Banner";
import Hero from "../components/Auctions/Hero/Hero";
import { Hero6} from "../containers";
const Auction = () => {
  return (
    <div>
      <Banner />
      <Hero />
      <Hero6/>
    </div>
  );
};

export default Auction;
