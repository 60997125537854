import React from "react";
import "./Hero3.css";
import { chart } from "../../../assets/Crowd/index.js"; // Import the required image

const Hero3 = () => {
  return (
    <div className="crowd-hero3-container section__padding">
      <div className="crowd-hero3-container-para">
        <p>Why MySft Investing?</p>
      </div>
      <div className="crows-hero3-main-text-container">
        <div className="crows-hero3-main-text-container-item1">
          <h1>Exploring Inverstments Beyond Stocks and Bonds</h1>
        </div>
        <div className="crows-hero3-main-text-container-item2 ">
          <div className="">
            <p>
              Uncover the potential benefits and risks of real estate ventures,
              from rental properties to real estate investment trusts (REITs).
              Learn valuable strategies to diversify your portfolio and build
              wealth through the power of property
            </p>
          </div>

          <div className="crows-hero3-main-text-container-item2-sub">
            <button className="crowd-hero3-main-text-btn1">Download App</button>
            <button className="crowd-hero3-main-text-btn2">Learn More</button>
          </div>
        </div>
      </div>
      <div className="crows-hero3-main-container2 ">
        <div className=" crows-hero3-main-img-container">
          <div className=" ">
            <img src={chart} alt="chart" />
          </div>
          <div className="crows-hero3-main-text-container2">
            <div>
              <p className="crows-hero3-main-text-container2-para">
                Other Returns
              </p>
            </div>
            <div className="crows-hero3-main-img-container">
              <h3 className="crows-hero3-main-text-container2-heading ">
                ₹ 14,382 B
              </h3>
              <div className="crows-hero3-main-text-container2-para2">
                <p>Update on</p>
                <p>July 1, 2023</p>
              </div>
            </div>
          </div>
          <div className="crows-hero3-main-text-container3">
            <div>
              <p className="crows-hero3-main-text-container2-para">
                Private/Commercial Real Estate Investment
              </p>
            </div>
            <div className="crows-hero3-main-img-container">
              <h3 className="crows-hero3-main-text-container2-heading2 ">
                ₹ 60,985 B
              </h3>
              <div className="crows-hero3-main-text-container2-para2">
                <p>Update on</p>
                <p>July 1, 2023</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero3;
