import React, { useState, useEffect } from "react";
import "./carousel.css"; // Create a CSS file to style your carousel

const textItems = [
  "MySft metric with detailed customer analytics",
  "Consectetur adipiscing elit",
  "Sed do eiusmod tempor incididunt",
  "Ut labore et dolore magna aliqua",
  "New item: This is a new slide in the carousel!",
]; // Replace with your own text items

const Carousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      // Auto-carousel functionality
      setActiveIndex((prevIndex) => (prevIndex + 1) % textItems.length);
    }, 3000); // Change the value (in milliseconds) to adjust the slide interval

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, []);

  return (
    <div className="carousel">
      <div className="carousel-inner">
        {textItems.map((text, index) => (
          <div
            key={index}
            className={`carousel-slide ${
              index === activeIndex ? "active" : ""
            }`}
            style={{ display: index === activeIndex ? "block" : "none" }}
          >
            <p>{text}</p>
          </div>
        ))}
      </div>

      <div className="carousel-indicators">
        {textItems.map((_, index) => (
          <div
            key={index}
            className={`indicator ${index === activeIndex ? "active" : ""}`}
            onClick={() => setActiveIndex(index)}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
