import React, { useState, useEffect, useRef } from "react";
import "./GetStarted.css"; // Make sure to create a CSS file for styling

const GetStarted = () => {
  const stepsData = [
    {
      question: "Provide your information",
      answer: "Give us some details on yourself and your firm.",
    },
    {
      question: "Select your location",
      answer:
        "Provide a zip code and we will provide you with the highest propensity prospects in a 50 mile radius.",
    },
    {
      question: "Check Out",
      answer:
        "After downloading the software, run the setup file and follow the installation instructions.",
    },
    {
      question: "Access your dashboard",
      answer:
        "Once installed, open the software and explore our awesome features to get started!",
    },
  ];

  const [activeStep, setActiveStep] = useState(null);
  const stepsRef = useRef([]);

  // Modify handleMouseWheel to handle scrolling regardless of pointer position
  const handleMouseWheel = (e) => {
    if (e.deltaY < 0) {
      // Scrolling up
      setActiveStep((prevStep) =>
        prevStep !== null ? Math.max(0, prevStep - 1) : null
      );
    } else {
      // Scrolling down
      setActiveStep((prevStep) =>
        prevStep !== null ? Math.min(stepsData.length - 1, prevStep + 1) : 0
      );
    }
  };

  useEffect(() => {
    stepsRef.current = stepsRef.current.slice(0, stepsData.length);

    // Attach event listener to the window object for scrolling
    window.addEventListener("wheel", handleMouseWheel);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("wheel", handleMouseWheel);
    };
  }, [stepsData]);

  useEffect(() => {
    if (activeStep !== null) {
      stepsRef.current[activeStep].scrollIntoView({ behavior: "smooth" });
    }
  }, [activeStep]);

  return (
    <div className="get-started-container">
      <h2>How to Get Started ?</h2>
      <div className="steps-container">
        {stepsData.map((step, index) => (
          <div
            key={index}
            className={`step ${activeStep === index ? "active" : ""}`}
            onClick={() => setActiveStep(index)}
            ref={(el) => (stepsRef.current[index] = el)}
          >
            <p className="step-question">{step.question}</p>
            {activeStep === index && (
              <div className="step-answer">{step.answer}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default GetStarted;
