import React from "react";
import PropTypes from "prop-types";
import "./Hero4.css";
import { motion } from "framer-motion";

const DynamicColumns = ({ columnData, columnClassNames }) => {
  const handleContainerTap = (index) => {
    // The swing animation configuration
    const swingAnimation = {
      rotate: [-10, 10, -5, 0],
      transition: { duration: 0.5 },
    };

    // Apply the swing animation to the selected container
    const containerId = `column-${index}`;
    const containerElement = document.getElementById(containerId);
    if (containerElement) {
      containerElement.style.transformOrigin = "center";
      containerElement.style.willChange = "transform";
      containerElement.style.pointerEvents = "none";
      containerElement.style.userSelect = "none";

      // Apply the animation and reset the styles after animation is complete
      containerElement.animate(swingAnimation, { fill: "forwards" }).onfinish =
        () => {
          containerElement.style.transform = "";
          containerElement.style.pointerEvents = "";
          containerElement.style.userSelect = "";
        };
    }
  };

  return (
    <div className="section4-container section__padding">
      <div>
        <h1 className="section4-heading">
          Event goers finally enjoy an epic experience from purchase through
          performance
        </h1>
      </div>
      <div className="row">
        {columnData.map((columnContent, index) => (
          <motion.div
            key={index}
            id={`column-${index}`}
            className={`column ${columnClassNames[index]}`}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 * (index + 1) }}
            onTap={() => handleContainerTap(index)}
          >
            <motion.h1>{columnContent}</motion.h1>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

DynamicColumns.propTypes = {
  columnData: PropTypes.arrayOf(PropTypes.string).isRequired,
  columnClassNames: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const Hero4 = () => {
  const columnData = [
    "No duplication with safe and fair resale",
    "Paperless for the planet",
    "Transparent transaction and fees",
    "POAP (proof of attendance protocol)",
  ];

  const columnClassNames = [
    "container1",
    "container2",
    "container3",
    "container4",
  ];

  return (
    <div>
      <DynamicColumns
        columnData={columnData}
        columnClassNames={columnClassNames}
      />
    </div>
  );
};

export default Hero4;
