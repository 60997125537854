// HorizontalTabs.js
import React, { useState } from "react"; // Import useState hook
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "./HorizontalTabs.css"; // Import the CSS file for styling
import { location2s } from '../../../src/assets/Buy/index';
const HorizontalTabs = () => {
  const tabTitles = [
    "Residential plots",
    "Commercial plots",
    "Agricultural lands",
  ];

  const popularLocations = [
    "Kondapur",
    "Hitech city",
    "Gachibowli",
    "Madhapur",
    "Begumpet",
  ];

  // Add state to manage the search bar value
  const [searchValue, setSearchValue] = useState("");

  // Function to handle inserting city into the search bar
  const handleCityClick = (city) => {
    setSearchValue(city);
  };

  return (
    <Tabs>
      <TabList className="horizontal-tab-list">
        {tabTitles.map((title, index) => (
          <Tab key={index}>{title}</Tab>
        ))}
      </TabList>

      {tabTitles.map((title, index) => (
        <TabPanel key={index}>
          <div className="tab-content">
            <div>
              <img
                className="location-icon"
                src={location2s}
                alt="location-icon"
              />
            </div>
            <select className="dropdown">
              <option>Hyderabad</option>
              <option>Hyderabad</option>
              <option>Hyderabad</option>
            </select>
            <input
              type="text"
              className="search-bar"
              placeholder="Search for locality, landmark"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <button className="search-button">Search</button>
          </div>

          <div className="popular-locations">
            <p className="inline-text">Popular Locations: </p>
            <div className="inline-cities">
              {popularLocations.map((location, index) => (
                <span
                  key={index}
                  onClick={() => handleCityClick(location)} // Call the click handler to insert city into search bar
                  className="city-item"
                >
                  {index >= 0 && " + "}
                  {location}
                </span>
              ))}
            </div>
          </div>
        </TabPanel>
      ))}
    </Tabs>
  );
};

export default HorizontalTabs;
