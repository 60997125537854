import React, { useState } from "react";
import "./Faqs.css";
import {Group134} from "../../../assets/Contact/index.js";

const FaqSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "What is a personal loan?",
      answer:
        "A personal loan is an unsecured loan that allows you to borrow a certain amount of money from a lender without providing any collateral. You can use this loan for any purpose, such as paying for a wedding, consolidating debts, or financing a home renovation.",
    },
    {
      question: "What is the interest rate for personal loan?",
      answer: "You can easily track your order by logging into your account...",
    },
    {
      question: "What are the eligibility criteria?",
      answer: "Yes, we offer international shipping to most countries...",
    },
    {
      question: "How can I apply for a personal loan?",
      answer: "Yes, we offer international shipping to most countries...",
    },
    {
      question: "What are the repayment options for a personal loan?",
      answer: "Yes, we offer international shipping to most countries...",
    },
    {
      question: "How can I apply for a personal loan?",
      answer: "Yes, we offer international shipping to most countries...",
    },
    // Add more FAQs
  ];

  const toggleFaq = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faqs">
      {faqs.map((faq, index) => (
        <div key={index} className="faqs-section">
          <div className="faqs-question" onClick={() => toggleFaq(index)}>
            <span>{faq.question}</span>
            <span
              className={`faqs-arrow-icon ${
                activeIndex === index ? "active" : ""
              }`}
            >
              <img className="faq-arrow-img" src={Group134} alt="Arrow" />
            </span>
          </div>
          {activeIndex === index && (
            <div className="faqs-answer">{faq.answer}</div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FaqSection;
