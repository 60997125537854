import React, { useRef, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/scrollbar";
import { Scrollbar } from "swiper/modules";
import Section2 from "../section2/section2";
import { MaskGroup36, MaskGroup35, MaskGroup37 } from "../../assets/Home";
import { motion } from "framer-motion"; // Import Framer Motion
import "./Hero.css";

const Hero = () => {
  const swiperRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [totalSlides, setTotalSlides] = useState(0);

  useEffect(() => {
    const swiperInstance = swiperRef.current.swiper;

    // Update the current slide and total slides on initialization and slide change
    setCurrentSlide(swiperInstance.activeIndex);
    setTotalSlides(swiperInstance.slides.length);

    // Add event listeners for mousewheel and keydown events
    swiperInstance.on("slideChange", handleSlideChange);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      // Clean up the event listeners on component unmount
      swiperInstance.off("slideChange", handleSlideChange);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleSlideChange = () => {
    setCurrentSlide(swiperRef.current.swiper.activeIndex);
  };

  const handleKeyDown = (event) => {
    if (event.key === "ArrowLeft") {
      swiperRef.current.swiper.slidePrev();
    } else if (event.key === "ArrowRight") {
      swiperRef.current.swiper.slideNext();
    }
  };

  return (
    <div className="section__padding">
      <div>
        {/* Add Framer Motion to the h1 heading */}
        <motion.h1
          className="hero-heading"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          We are eliminating the issues <br /> <span>Traditional Buying</span>
        </motion.h1>
      </div>
      <>
        <Swiper
          ref={swiperRef}
          scrollbar={{
            hide: true,
          }}
          modules={[Scrollbar]}
          className="mySwiper"
        >
          <SwiperSlide>
            <Section2
              title="Set your budget"
              description="Empower Financial Success: Set Budget, Prioritize Goals, Make Informed Decisions for a Bright Future."
              image={MaskGroup35}
              containerClassName="container-2"
            />
          </SwiperSlide>
          <SwiperSlide>
            <Section2
              title="Get Multiple plot options"
              description="Empower Financial Success: Set Budget, Prioritize Goals, Make Informed Decisions for a Bright Future."
              image={MaskGroup36}
              containerClassName="container-3"
            />
          </SwiperSlide>
          <SwiperSlide>
            <Section2
              title="Hassle-free Investment"
              description="Empower Financial Success: Set Budget, Prioritize Goals, Make Informed Decisions for a Bright Future."
              image={MaskGroup37}
              containerClassName="container-4"
            />
          </SwiperSlide>
        </Swiper>
      </>
    </div>
  );
};

export default Hero;
