import React from "react";
import Banner from "../components/LIst/Banner/Banner";
import Hero from "../components/LIst/Hero/Hero";
import Hero2 from "../components/LIst/Hero2/Hero2";
import Hero3 from "../components/LIst/Hero3/Hero3";
import Hero4 from "../components/LIst/Hero4/Hero4";
const Buy = () => {
  return (
    <div >
      <Banner />
      <Hero />
      <Hero2 />
      <Hero3 />
      <Hero4 />
    </div>
  );
};

export default Buy;
