import React from "react";
import { MaskGroup15 } from "../../assets/Home";
import "./Vertical.css";
import { motion } from "framer-motion";


const VerticalSlider = () => {
  return (
    <div>
      <div className={"MuiGrid-root css-1c4qchg vertical-slider-container"}>
        <div className={"MuiBox-root css-1vunpsv .vertical-slider-container2"}>
          <div>
            {/* Add Framer Motion to the h1 heading */}
            <motion.h1
              className="hero-heading"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              We are eliminating the issues <br />{" "}
              <span>Traditional Buying</span>
            </motion.h1>
            
          </div>
        </div>

        <div className={"MuiBox-root css-1cjmydp vertical-items-container"}>
          <div className={"MuiBox-root css-14qp7x7 vertical-items-container2"}>
            <div
              className={
                "MuiGrid-root MuiGrid-container css-1ttj57f vertical-items-container-main "
              }
            >
              <div
                className={
                  "MuiGrid-root MuiGrid-item MuiGrid-grid-sm-6 css-k77snf vertcical-item-text-container"
                }
                titlecolor={"#D7F3F6"}
              >
                {/* Add Framer Motion to the first vertical slide */}
                <motion.h3
                  className={
                    "MuiTypography-root MuiTypography-h3 css-izrd3q vertcical-item-text-container-heading1"
                  }
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8 }}
                >
                  Set your budget
                </motion.h3>

                <motion.p
                  className={
                    "MuiTypography-root MuiTypography-body2 css-fmgsmw vertcical-item-text-container-heading1-para1"
                  }
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8, delay: 0.2 }}
                >
                  Empower Financial Success: Set Budget, Prioritize Goals, Make
                  Informed Decisions for a Bright Future.
                </motion.p>
              </div>
              <div
                className={
                  "MuiGrid-root MuiGrid-item MuiGrid-grid-sm-6 css-1qd4wfi vertcical-item-text-container-img1"
                }
              ></div>
            </div>

            <div
              className={
                "MuiGrid-root MuiGrid-container css-19r6al vertical-slide2-container"
              }
              bggradient={
                "180deg, #B0ADE9 -22.35%, rgba(176, 173, 233, 0.33) 47.17%, rgba(176, 173, 233, 0) 100%"
              }
            >
              <div
                className={
                  "MuiGrid-root MuiGrid-item MuiGrid-grid-sm-6 css-19tnhzb vertcical-item-text-container"
                }
                titlecolor={"#FED0AB"}
              >
                {/* Add Framer Motion to the second vertical slide */}
                <motion.h3
                  className={
                    "MuiTypography-root MuiTypography-h3 css-izrd3q vertcical-item-text-container-heading1"
                  }
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8 }}
                >
                  Get Multiple plot options
                </motion.h3>
                <motion.p
                  className={
                    "MuiTypography-root MuiTypography-body2 css-fmgsmw vertcical-item-text-container-heading1-para1 "
                  }
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8, delay: 0.2 }}
                >
                  Empower Financial Success: Set Budget, Prioritize Goals, Make
                  Informed Decisions for a Bright Future.
                </motion.p>
              </div>
              <div
                className={
                  "MuiGrid-root MuiGrid-item MuiGrid-grid-sm-6 css-1lxknbp vertcical-item-text-container-img2"
                }
              ></div>
            </div>
            <div
              className={
                "MuiGrid-root MuiGrid-container css-13okydn vertical-slide3-container"
              }
            >
              <div
                className={
                  "MuiGrid-root MuiGrid-item MuiGrid-grid-sm-6 css-18zyxdg  vertcical-item-text-container"
                }
                titlecolor={"#3FE5A4"}
              >
                {/* Add Framer Motion to the third vertical slide */}
                <motion.h3
                  className={
                    "MuiTypography-root MuiTypography-h3 css-izrd3q vertcical-item-text-container-heading1"
                  }
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8 }}
                >
                  Hassle-free Investment
                </motion.h3>
                <motion.p
                  className={
                    "MuiTypography-root MuiTypography-body2 css-fmgsmw vertcical-item-text-container-heading1-para1"
                  }
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8, delay: 0.2 }}
                >
                  We analyze legal documents for lands in different zones,
                  including Title deeds, NA orders, ECs, RCs, tax receipts, FSI
                  details, and connectivity issues. Physical verification
                  ensures comprehensive assessment.
                </motion.p>
              </div>
              <div
                className={
                  "MuiGrid-root MuiGrid-item MuiGrid-grid-sm-6 css-djbuw vertcical-item-text-container-img3"
                }
              ></div>
            </div>
            <div
              className={
                "MuiGrid-root MuiGrid-container css-1jftxoc vertical-slide4-container"
              }
              bggradient={
                "180deg, #68C6F5 -22.35%, rgba(104, 198, 245, 0.33) 47.17%, rgba(104, 198, 245, 0) 100%"
              }
            >
              <div
                className={
                  "MuiGrid-root MuiGrid-item MuiGrid-grid-sm-6 css-1gkb66g vertcical-item-text-container"
                }
                titlecolor={"#B0ADE9"}
              >
                {/* Add Framer Motion to the fourth vertical slide */}
                <motion.h3
                  className={
                    "MuiTypography-root MuiTypography-h3 css-izrd3q vertcical-item-text-container-heading1"
                  }
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8 }}
                >
                  Weak event
                </motion.h3>
                <motion.p
                  className={
                    "MuiTypography-root MuiTypography-body2 css-fmgsmw vertcical-item-text-container-heading1-para1"
                  }
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8, delay: 0.2 }}
                >
                  Fan experiences often start confusingly and end abruptly.
                </motion.p>
              </div>
              <div
                className={
                  "MuiGrid-root MuiGrid-item MuiGrid-grid-sm-6 css-97ruw vertcical-item-text-container-img4"
                }
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerticalSlider;
