import React, { useState } from "react";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import "./navbar.css";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const location = useLocation(); // Get the current location

  // Function to check if a link is active
  const isLinkActive = (path) => {
    return location.pathname === path;
  };

  return (
    <div className="gpt3__navbar">
      <div className="gpt3__navbar-links_logo">
        <h2>
          <Link to="/" className={isLinkActive("/home") ? "active" : ""}>
            My Sft
          </Link>
        </h2>
      </div>
      <div className="gpt3__navbar-links">
        <div className="gpt3__navbar-links_container">
          <p>
            <Link to="/Buy" className={isLinkActive("/Buy") ? "active" : ""}>
              Buy
            </Link>
          </p>
          <p>
            <Link to="/Sell" className={isLinkActive("/Sell") ? "active" : ""}>
              List Your Property
            </Link>
          </p>
          <p>
            <Link
              to="/Crowd"
              className={isLinkActive("/Crowd") ? "active" : ""}
            >
              Crowd funding
            </Link>
          </p>
          <p>
            <Link
              to="/Auction"
              className={isLinkActive("/Auction") ? "active" : ""}
            >
              Auctions
            </Link>
          </p>
          <p>
            <Link to="#blog" className={isLinkActive("#blog") ? "active" : ""}>
              Loans
            </Link>
          </p>
        </div>
      </div>
      <div className="gpt3__navbar-sign">
        <button type="button">Register / Login</button>
      </div>
      <div className="gpt3__navbar-menu">
        {toggleMenu ? (
          <RiCloseLine
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <RiMenu3Line
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(true)}
          />
        )}
        {toggleMenu && (
          <div className="gpt3__navbar-menu_container scale-up-center">
            <div className="gpt3__navbar-menu_container-links">
              <p>
                <Link
                  to="/Buy"
                  className={isLinkActive("/Buy") ? "active" : ""}
                >
                  Buy
                </Link>
              </p>
              <p>
                <Link
                  to="/Sell"
                  className={isLinkActive("/Sell") ? "active" : ""}
                >
                  List Your Property
                </Link>
              </p>
              <p>
                <Link
                  to="/Crowd"
                  className={isLinkActive("/Crowd") ? "active" : ""}
                >
                  Crowd funding
                </Link>
              </p>
              <p>
                <Link
                  to="/Auction"
                  className={isLinkActive("/Auction") ? "active" : ""}
                >
                  Auctions
                </Link>
              </p>
              <p>
                <Link
                  to="#blog"
                  className={isLinkActive("#blog") ? "active" : ""}
                >
                  Loans
                </Link>
              </p>
            </div>
            <div className="gpt3__navbar-menu_container-links-sign">
              <button type="button">Register / Login</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
