import React from "react";
import "./Hero2.css"; // Import the CSS file with the styles
import { vuesaxChart, vuesaxWallet, vuesaxMenu } from "../../../assets/Crowd/index.js"; // Import the required image

const TimelineContainer = ({ data }) => {
  return (
    <div className="crowd-hero2-container section__padding ">
      <div>
        <h1 className="crowd-hero2-container-main-heading">
          We Simplify the Your Real Estate Inversting Journey{" "}
        </h1>
      </div>
      <div className="timeline-container">
        {data.map((item, index) => (
          <div
            className={`timeline-container-item ${item.className}`}
            key={index}
          >
            <div>
              {item.imageUrl && (
                <img src={item.imageUrl} alt="" className="timeline-image" />
              )}
            </div>
            <div>
              {item.heading && (
                <h1
                  className={`timeline-heading ${
                    index === 2 && "container2-heading"
                  }`}
                >
                  {item.heading}
                </h1>
              )}
              {item.description && (
                <p className="timeline-description">{item.description}</p>
              )}
              {item.buttonText && (
                <button className="timeline-button">{item.buttonText}</button>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const App = () => {
  const timelineData = [
    {
      imageUrl: vuesaxMenu,
      heading: "Sign up",
      description:
        "Sign up in minutes and browse rigorously vetted Real estate investments.",
      className: "crwod-hero2-container1", // Add the className property for each timeline item
    },
    {
      imageUrl: vuesaxWallet,
      heading: "Invest",
      description:
        "Invest in professionally managed assets that fit your strategy, starting with just $5k.",

      className: "crwod-hero2-container2", // Add the className property for each timeline item
    },
    {
      imageUrl: vuesaxChart,
      heading: "Grow",
      description:
        "Grow a strong real estate portfolio, supported by industry-leading tech, asset management & investor services.",
      className: "crwod-hero2-container3", // Add the className property for each timeline item
    },
    {
      buttonText: "Invest now",
      className: "crwod-hero2-container4", // Add the className property for each timeline item
    },
  ];

  return (
    <div>
      
      <TimelineContainer data={timelineData} />
    </div>
  );
};

export default App;
