import React from "react";
import "./brand.css"; // Import the CSS file as a module
import { Group155, Group156, Group157, Group158 } from "../../assets/Home";
import StarWrapper from "../../Wrapper/Wrapper";

const Brand = () => {
  return (
    <div className="gpt3__brand section__padding" >
      <img src={Group155} alt="google" />

      <img src={Group156} alt="dropbox" />

      <img src={Group157} alt="slack" />

      <img src={Group158} alt="shopify" />
    </div>
  );
};

export default StarWrapper(Brand ,"brand");
