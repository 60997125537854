import React from "react";
import locationicon  from "../../assets/Auction/locationicon.png";
import "./AuctionCards.css";

const AuctionCard = ({ image, description, date, location, amount, icon }) => (
  <div className="auction-card-container">
    <img src={image} alt="Card" />
    <div className="auction-card-container-sub1">
      <h2>{description}</h2>
      <p className="auction-card-container-date"> {date}</p>
    </div>
    <div className="auction-card-container-sub">
      <div className="auction-card-container-sub-img">
        <img src={locationicon} alt="icon" />
      </div>

      <p> {location}</p>
    </div>
    <p className="auctuion-card-amount"> {amount}</p>
    <p className="auctuion-card-bidding-text ">Biding base</p>
    <p className="auctuion-card-bidding-view-text">
      <a >View Detail</a>
    </p>
  </div>
);

export default AuctionCard;
