import React from "react";
import people from "../../assets/people.png";
import "./header.css";
import { Group146 } from "../../assets/Home";
import { motion } from "framer-motion";
import Starwrapper from '../../Wrapper/Wrapper'

const Header = () => (
  <div className="">
    <div className="gpt3__header" id="home">
      <div className="gpt3__header-content">
        {/* Add Framer Motion to the first h1 heading */}
        <motion.h1
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          Empowering <br />
          individuals in their
        </motion.h1>

        {/* Add Framer Motion to the second h1 heading */}
        <motion.h1
          className="header-span-heading"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.3 }}
        >
          Land Investments
        </motion.h1>

        {/* Add Framer Motion to the button */}
        <motion.div
          className="gpt3__header-content__input"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.5 }}
        >
          <button type="button">Book Now</button>
        </motion.div>

        <div className="gpt3__header-content__people">
          {/* Add Framer Motion to the people image */}
          <motion.img
            src={people}
            alt="People"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.7 }}
          />
          <motion.p
            className="header-span-heading"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.3 }}
          >
            1,600 people requested access a visit in the last 24 hours
          </motion.p>
        </div>
      </div>

      {/* Add Framer Motion to the Group146 image */}
      <motion.div
        className="gpt3__header-image"
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8, delay: 0.9 }}
      >
        <img src={Group146} alt="Group" />
      </motion.div>
    </div>
  </div>
);

export default Starwrapper(Header, "header");
