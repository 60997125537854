import React from "react";
import "./Banner.css";
import Navbar from "../../navbar/Navbar";
import HorizontalTabs from "../../../containers/HorizontalTabs/HorizontalTabs";

const Banner = () => {
  return (
    <div>
      <div className="">
        <div className="Auction-banner-container-bg">
          <Navbar />
          <div className="Auction-banner-container section__padding">
            <h1 className="Auction-banner-main-heading">
              Auctioning Your Way to Prime Real Estate
            </h1>
            <HorizontalTabs />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
