import React from "react";
import "./Card.css";
import {line,chart,percentage} from "../../assets/List/index";

const data = [
  {
    id: 1,
    imgSrc: line,
    title: "In your backyard",
    description:
      "Radius based leads within 20-miles from you, prioritizing those properties closer.",
    className: "container-blue", // Add a class name for the first container
  },
  {
    id: 2,
    imgSrc: chart,
    title: "High net worth",
    description:
      "Radius based leads within 20-miles from you, prioritizing those properties closer.",
    className: "container-green", // Add a class name for the second container
  },
  {
    id: 3,
    imgSrc: percentage,
    title: "High propensity",
    description:
      "Radius based leads within 20-miles from you, prioritizing those properties closer.",
    className: "container-orange", // Add a class name for the third container
  },
];

const   Card = () => {
  return (
    <div className="grid-container">
      {data.map((item) => (
        <div key={item.id} className={`item ${item.className}`}>
          <img
            src={item.imgSrc}
            className="card-icon"
            alt={`Image for ${item.title}`}
          />
          <h2>{item.title}</h2>
          <p>{item.description}</p>
        </div>
      ))}
    </div>
  );
};

export default Card;
