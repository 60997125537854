import React from "react";
import "./Hero.css";
import HorizontalCarousel from "../../../containers/HorizontalCarousel/HorixontalCarousel";

import { Union6, Group160 } from "../../../assets/List/index";
import { Group209 } from "../../../assets/Buy/index";

const Hero = () => (
  <div className="buy-hero-bg">
    <div className="buy-hero-container">
      <img
        src={Union6}
        className="buy-hero-container-img"
        alt="Group-1"
        border="0"
      />
      <h1>Our Platfrom</h1>
      <img className="buy-hero-container-img2" src={Group160} alt="Group160" />
      <p>Find your best place to Invest now</p>
      <div>
        <img
          className="buy-hero-sub-container-img"
          src={Group209}
          alt="Group209"
        />
      </div>
    </div>
    <div className="buy-hero-sub-container section__padding">
      <div>
        <HorizontalCarousel />
      </div>
    </div>
  </div>
);

export default Hero;
