import  React,{useEffect}  from "react";
import "./Hero.css";
import { syncArrows, UpArrow, increase } from "../../../assets/Crowd/index.js"; // Import the required image
import Aos from "aos";
import "aos/dist/aos.css";

const Hero = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  
  const containersData = [
    {
      title: "Container 1",
      imageSrc: "", // Add the image source here for Container 1
      item: {
        text: "Keep",
        icon: syncArrows,
      },
      classname: "crowd-hero1-container1",
      description: "A high-yield, short-term alternative to savings accounts.",
      buttonText: "Learn more",
      backgroundColor: "#f0f0f0", // Add the background color for Container 1
    },
    {
      title: "Container 2",
      imageSrc: "", // Add the image source here for Container 2
      item: {
        text: "Earn",
        icon: UpArrow,
      },
      classname: "crowd-hero1-container2",
      description: "Real estate investments focused on near-term income.",
      buttonText: "Learn more",
      backgroundColor: "#e0e0e0", // Add the background color for Container 2
    },
    {
      title: "Container 3",
      imageSrc: "", // Add the image source here for Container 3
      item: {
        text: "Grow",
        icon: increase,
      },
      classname: "crowd-hero1-container3",
      description:
        "Real estate investments focused on growth and appreciation.",
      buttonText: "Learn more",
      backgroundColor: "#d0d0d0", // Add the background color for Container 3
    },
  ];

  return (
    <div>
      <div className="crowd-hero1-main-text-container" data-aos="fade-down">
        <h1 className="crowd-hero1-main-heading">
          A Unique Wealth Generation <span>Ecosystem</span>
        </h1>
        <p className="crowd-hero1-main-para ">
          Mysft provides a range of flexible options to fit your objectives, and
          three complementary strategies to support the evolution of your
          portfolio.
        </p>
      </div>

      <div className="crowd-hero-conatiner" data-aos="fade-up">
        {containersData.map((data, index) => (
          <div key={index}>
            <div className={`crowd-hero1-maincontainer ${data.classname}`}>
              <div>
                <div className="crowd-hero-item-container">
                  <div>
                    <h1>{data.item.text}</h1>
                  </div>
                  <div>
                    <img src={data.item.icon} alt="sync" />
                  </div>
                </div>
                <div>
                  <p className="crowd-hero1-item-para">{data.description}</p>
                  <div className="crowd-hero1-button-container">
                    <button className="crowd-hero1-item-button ">
                      {data.buttonText}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Hero;
