import React from "react";
import "./Hero.css";
import Card from "../../../containers/Card/Card";

import {
  Union6,
  diamond,
  Group160,
  MaskGroup116,
} from "../../../assets/List/index";

const Hero = () => (
  <div className="list-hero-bg">
    <div className="list-hero-container">
      <img
        src={Union6}
        className="list-hero-container-img"
        alt="Group-1"
        border="0"
      />
      <h1>Our Platfrom</h1>
    </div>
    <div className="list-hero-container">
      <div className="list-hero-sub-container section__padding">
        <img src={diamond} alt="diamond" />

        <h2>Being Exculsive</h2>
        <p>Exclusive listing leads pushed to your platform</p>
        <button>Get Started</button>
      </div>
      <div className="list-hero-sub-container-img">
        <img src={Group160} alt="Union6" />
      </div>
      <div>
        <img
          className="list-hero-sub-container-img2"
          src={MaskGroup116}
          alt="group"
        />
      </div>
    </div>
    <Card />
  </div>
);

export default Hero;
