import React from "react";
import "./Banner.css";
import Navbar from "../../navbar/Navbar";
import { Icons } from "../../../assets/List/index";

const Banner = () => {
  const handleScrollClick = () => {
    const distanceToScroll = 750; // Adjust this value as needed
    window.scrollTo({
      top: distanceToScroll,
      behavior: "smooth",
    });
  };

  return (
    <div className="list-banner-bg">
      <Navbar />
      <div className="list-banner-container">
        <h1 className="list-banner-main-heading">
          Simplify the property Listings: Event Driven Leads at Your Service
        </h1>
        <p className="list-banner-main-para">
          Not your average smart farming, not statistical analysis, but
          actionable behaviors.
        </p>
        <img
          src={Icons}
          className="list-bannner-scroll-button"
          alt="Group-1"
          border="0"
          onClick={handleScrollClick} // Add this onClick event handler
        />
        <p className="scroll-text">Scroll down</p>
      </div>
    </div>
  );
};

export default Banner;
