import React from "react";
import "./Banner.css";
import Navbar from "../../navbar/Navbar";
const Banner = () => {
    return (
      <div className="">
        <div className="banner-container">
          <Navbar />
          <div className="banner-text-container section__padding">
            <h1 className="banner__title">Invest in</h1>
            <h1 className="banner__title2 ">Something real</h1>
            <button className="banner-button">
              Sign up <span></span>
            </button>
          </div>
        </div>
      </div>
    );
}

export default Banner;