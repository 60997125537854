import React from "react";
import "./Hero2.css";
import VerticalTabs2 from "../../../containers/VerticalTabs2/VerticalTabs2";
import InsightSection from "../../../containers/Insight/Insight";

const Hero2 = () => {
    return (
      <div>
        <div className="buy-hero2-bg">
          <div className="buy-hero2-text-container">
            <p className="buy-hero2-title">Why MySft?</p>
            <h1>Take a big step into the future of investment</h1>
          </div>
        </div>
        <div className="buy-hero2-tabs-container">
          <div className="buy-hero2-tabs">
            <VerticalTabs2 />
          </div>
        </div>
        <div className="section__padding">
          <InsightSection />
        </div>
      </div>
    );
};

export default Hero2;
