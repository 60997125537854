import React, { useState, useEffect } from "react";
import "./HorizontalCarousel.css"; // Create a CSS file to style your carousel
import { Group109, location2s } from "../../assets/Buy";

const cardItems = [
  {
    id: 1,
    title: "Card 1",
    description: "Down payment starts from",
    imageUrl: Group109,
    location: "Gachibowli",
    titile: "GHR Infra",
    area: "1800 Sqft",
    amount: "₹60000",
    icon: location2s,

    // Add more fields as needed for each card
  },
  {
    id: 2,
    title: "Card 1",
    description: "Down payment starts from",
    imageUrl: Group109,
    location: "Gachibowli",
    titile: "GHR Infra",
    area: "1800 Sqft",
    amount: "₹60000",
    icon: location2s,

    // Add more fields as needed for each card
  },
  {
    id: 3,
    title: "Card 1",
    description: "Down payment starts from",
    imageUrl: Group109,
    location: "Gachibowli",
    titile: "GHR Infra",
    area: "1800 Sqft",
    amount: "₹60000",
    icon: location2s,

    // Add more fields as needed for each card
  },
  // Add more card objects as needed
];

const HorizontalCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      // Auto-carousel functionality
      setActiveIndex((prevIndex) => (prevIndex + 1) % cardItems.length);
    }, 3000); // Change the value (in milliseconds) to adjust the slide interval

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, []);

  const goToSlide = (index) => {
    setActiveIndex(index);
  };

  return (
    <div className="HorizontalCarousel-carousel">
      <div className="HorizontalCarousel-inner">
        {cardItems.map((card, index) => (
          <div
            key={card.id}
            className={`HorizontalCarousel-slide ${
              index === activeIndex ? "active" : ""
            }`}
          >
            <div className="horizontal-card-container">
              <div className="horizontal-card-container-sub1">
                <img src={card.icon} alt={card.titile} />
                <p>{card.location}</p>
              </div>
              <div className="horizontal-card-container-sub2">
                <h2>{card.titile}</h2>
                <h2>{card.area}</h2>
              </div>
              <div>
                <h3 className="amount-text">{card.amount}</h3>
              </div>
              <div className="horizontal-card-container-sub2">
                <p>{card.description}</p>
                <div className="horizontal-card-container-img">
                  <div class="sti_container">
                    <button class="btn">
                      <span class="btn-icon">
                        <img src={card.imageUrl} alt="" aria-hidden="true"/>
                      </span>
                      <span class="btn-text">
                        Conatct Us
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="HorizontalCarousel-indicators">
        {cardItems.map((_, index) => (
          <button
            key={index}
            onClick={() => goToSlide(index)}
            className={index === activeIndex ? "active" : ""}
          />
        ))}
      </div>
    </div>
  );
};

export default HorizontalCarousel;
