// Hero2.js
import React, { useState, useEffect } from "react";
import VerticalTabs from "../../../containers/VerticalTabs/Verticaltabs";
import "./Hero2.css";

const Hero2 = () => {
  const [isVerticalTabsFixed, setIsVerticalTabsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const verticalTabsContainer = document.getElementById(
        "vertical-tabs-container"
      );
      if (verticalTabsContainer) {
        const rect = verticalTabsContainer.getBoundingClientRect();
        const scrollTop = window.scrollY;
        const containerTop = rect.top;

        setIsVerticalTabsFixed(scrollTop > containerTop);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="list-hero2-bg">
      <div className="list-hero2-main-text-container">
        <h1 className="list-hero2-title">Our Approach</h1>
        <p>
          Assist you throughout the selling process and ensure convenience in
          finding and purchasing the right plot for you.
        </p>
      </div>
      <div
        className={`vertical-tabs-container ${
          isVerticalTabsFixed ? "fixed" : ""
        }`}
      >
        <VerticalTabs />
      </div>
    </div>
  );
};

export default Hero2;
