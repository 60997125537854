import React from "react";
import "./Hero6.css";
import { Group41, Group42 } from "../../assets/Home";

const Hero6 = () => {
  return (
    <div className="container-hero6">
      <div className="">
        <h1>
          Embrance the future of plot Buying , Revolutizing Access and
          Simplicity
        </h1>
        <div className="container6-image">
          <img height={40} src={Group41} alt="playstore" />

          <img height={40} src={Group42} alt="istore" />
        </div>
      </div>
    </div>
  );
};

export default Hero6;
