import React, { useState, useRef, useEffect } from "react";
import "./Verticaltabs.css";
import {
  Group204,
  Group205,
  Group206,
  Group207,
} from "../../assets/List/index";

const VerticalTabs = () => {
  const tabsData = [
    {
      title: "Big Data",
      sr: "01",
      imageSrc: Group204,
      description:
        "We gather hundreds of millions of data points in order to perform advanced analytics to identify the highest propensity prospects.",
    },
    {
      title: "Event Driven",
      sr: "02",
      imageSrc: Group205,
      description:
        "We leverage real-tome event triggers to predict buying cycles of consumers, providing our users with prospects who would benefits services.",
    },
    {
      title: "Artifical Intelligence",
      sr: "03",
      imageSrc: Group206,
      description:
        "Our AI takes in hundreds of million of a data points to increase conversation rate for our users.",
    },
    {
      title: "Predictive Analytics",
      sr: "04",
      imageSrc: Group206,
      description:
        "Our predictive analytics utillize a combination of event-driven data, historical data, behavioral analytics, and real-time data to predict a customer’s propensity to use a service.",
    },
    // Add more tabs as needed
  ];

  const [activeTab, setActiveTab] = useState(0);
  const tabContentRef = useRef();
  const containerRef = useRef();

  // Debounce function to control scroll speed
  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  };

  const handleMouseWheel = debounce((event) => {
    event.preventDefault();
    const delta = Math.sign(event.deltaY);
    const container = containerRef.current;

    if (delta < 0 && activeTab > 0) {
      setActiveTab((prev) => {
        console.log("Prev" + tabsData.length);
        console.log("Prev" + prev);
        if (prev === 1) {
          // container.removeEventListener("wheel", handleMouseWheel, {
          //   passive: true,
          // });
        }
        return prev - 1;
      });
    } else if (delta > 0 && activeTab < tabsData.length - 1) {
      setActiveTab((prev) => {
        console.log(tabsData.length);
        console.log(handleMouseWheel);
        console.log(prev);
        // container.addEventListener("wheel");
        if (prev === tabsData.length) {
          debugger;
        }
        return prev + 1;
      });
    }
  }, 250); // Set the delay time here (250ms in this example)

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("wheel", handleMouseWheel);

      return () => {
        container.removeEventListener("wheel", handleMouseWheel);
      };
    }
  }, [activeTab, handleMouseWheel]);

  const handleMouseOver = (index) => {
    setActiveTab(index);
  };

  // Stop the page scroll when scrolling the vertical tabs
  useEffect(() => {
    const handleScroll = (event) => {
      event.preventDefault();
    };

    // const runScroll = (event) => {
    //   event.run();
    // };

    const container = containerRef.current;
    container.addEventListener("wheel", handleScroll, { passive: false });

    return () => {
      container.removeEventListener("wheel", handleScroll);
    };
  }, []);

  return (
    <div>
      <div className="tabs-container" ref={containerRef}>
        <div className="vertical-tabs">
          {tabsData.map((tab, index) => (
            <div
              key={index}
              className={`vertical-tab ${activeTab === index ? "active" : ""}`}
              onMouseOver={() => handleMouseOver(index)}
            >
              <p>
                {" "}
                <span className="vertical-tab-span">{tab.sr}</span>
                {tab.title}
              </p>
            </div>
          ))}
        </div>
        <div className="tab-content-container">
          <div className="vertical-tab-content" ref={tabContentRef}>
            <img
              src={tabsData[activeTab].imageSrc}
              alt={`Tab ${activeTab + 1}`}
            />
            <p>{tabsData[activeTab].description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerticalTabs;
