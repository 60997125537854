import React from "react";
import "./Hero4.css"; // Import the CSS file with the styles
import { Group187 } from "../../../assets/Crowd/index.js"; // Import the required image

const Hero4 = () => {
  return (
    <div className="crowd-hero4-container section__padding ">
      <div>
        <h1 className="crowd-hero4-container-main-heading ">
          How does real estate crowdfunding work?
        </h1>
      </div>
      <div className="crowd-hero4-container-main-para">
        <p>
          CRE crowdfunding allows multiple investors to pool their money and
          collectively invest in larger real estate projects than they could on
          their own.
        </p>
      </div>
      <div className="crowd-hero4-container-main-img ">
        <img src={Group187} alt="crowd-hero4-img" />
      </div>
      <div className="crowd-hero4-container-sub">
        <div>
          <button className="crowd-hero4-container-sub-button">
            Invest now
          </button>
        </div>
        <div className="crowd-hero4-container-sub-text">
          <p>
            Join a real estate crowdfunding deal, pooling capital with other
            investors to fund property projects, aiming for lucrative returns on
            investment.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Hero4;
