import React from "react";
import { Hero2, Hero3, Hero4, Hero5, Hero6,Header } from "../containers";
import Slider from "../components/Slider/Slider";
import { Navbar } from "../components";

const Home = () => {
  return (
    <div>
      <div className="home-bg">
        <Navbar />
        <Header />

      </div>

      <Slider/>
      <Hero2 />
      <Hero3 />
      <Hero4 />
      <Hero5 />
      <Hero6 />
    </div>
  );
};

export default Home;
