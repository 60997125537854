import React from "react";
import GetStarted from "../../../containers/GetStarted/GetStarted";
import { MaskGroup32 } from "../../../assets/List/index";

import "./Hero3.css";
const Hero3 = () => {
  return (
    <div className="list-hero3-container">
      <div className="list-hero3-container-main">
        <div className="list-hero3-container-main-img">
          <img src={MaskGroup32} alt="logo" />
        </div>
        <div>
          <GetStarted />
        </div>
      </div>
    </div>
  );
};

export default Hero3;
