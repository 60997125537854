import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./section2.css";
import "animate.css";

const Section2 = ({ title, description, image, containerClassName }) => {
  const containerClasses = classnames("container", containerClassName);

  return (
    <div >
      <div className={containerClasses}>
        <div className="content">
          <h1>{title}</h1>
          <p>{description}</p>
        </div>
        <div className="image ">
          <img src={image} alt="section2" />
        </div>
      </div>
    </div>
  );
};

Section2.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  containerClassName: PropTypes.string,
};

export default Section2;
