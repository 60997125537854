import React from "react";
import Banner from "../components/Contact/Banner/Banner";
import Hero from "../components/Contact/Banner/Hero/Hero";

const ContactUs = () => {
    return (
      <div className="contact-hero-bg " autoscroll="true">
        <Banner />
        <Hero />
      </div>
    );
}
    
export default ContactUs;