import React from "react";
import "./Hero2.css";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Hero2 = () => {
  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger the animation once
    threshold: 0.2, // The percentage of the section that should be visible to trigger the animation
  });

  return (
    <div className="container-hero2 section__padding" ref={ref}>
      <div className="container-hero2-text">
        {/* Add Framer Motion to the h1 heading */}
        <motion.h1
          className="container-hero2-text-heaing"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 20 }}
          transition={{ duration: 0.8 }}
        >
          From Legal Hassles to Peaceful Transactions:
        </motion.h1>
        {/* Add Framer Motion to the second h1 heading */}
        <motion.h1
          className="container-hero2-text-gradient"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 20 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          We've Got Your Back
        </motion.h1>
        {/* Add Framer Motion to the paragraph */}
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 20 }}
          transition={{ duration: 0.8, delay: 0.4 }}
        >
          Let us alleviate the burden of property legalities. Our dedicated team
          manages all aspects of buying and selling, ensuring compliance,
          documentation, and peace of mind throughout the process. Experience
          hassle-free property transactions with us.
        </motion.p>
        {/* Add Framer Motion to the button */}
        <motion.button
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 20 }}
          transition={{ duration: 0.8, delay: 0.6 }}
        >
          Know more
        </motion.button>
      </div>
    </div>
  );
};

export default Hero2;
