import React from "react";
import Banner from "../components/crowd/Banner/Banner";
import "./index.css";
import Hero from "../components/crowd/Hero/Hero";
import Hero2 from "../components/crowd/Hero2/Hero2";
import Hero3 from "../components/crowd/Hero3/Hero3";
import Hero4 from "../components/crowd/Hero4/Hero4";
const Crowd = () => {
  return (
    <div className="crowd-bg">
      <Banner />
      <Hero />
      <Hero2 />
      <Hero3 />
      <Hero4 />
    </div>
  );
};

export default Crowd;
