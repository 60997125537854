import React from "react";
import "./Insight.css"; // You can define your own CSS styles
import {
  MaskGroup96,
  MaskGroup97,
  MaskGroup98,
  MaskGroup99,
} from "../../assets/Buy";

const InsightSection = () => {
  const insights = [
    {
      id: 1,
      imageUrl: MaskGroup96,
      date: "January 12, 2023",
      description:
        "Diversifying Your Portfolio: Real Estate Investment Opportunities",
      para: "Learn how to boost returns in real estate investing with expert strategies and valuable tips.",
    },
    {
      id: 2,
      imageUrl: MaskGroup97,
      date: "March 23, 2023",
      description:
        "Maximizing Returns: Smart Strategies for Real Estate Investment",
      para: "Learn how to boost returns in real estate investing with expert strategies and valuable tips.",
    },
    {
      id: 3,
      imageUrl: MaskGroup98,
      date: "June 23, 2023",
      description:
        "Building Wealth with Real Estate: Expert Insights and Advice",
      para: "Learn how to boost returns in real estate investing with expert strategies and valuable tips.",
    },
    {
      id: 4,
      imageUrl: MaskGroup99,
      date: "July 20, 2023",
      description:
        "Unlocking Opportunities: How to Start Investing in Real Estate",
      para: "Learn how to boost returns in real estate investing with expert strategies and valuable tips.",
    },
    // Add more insights as needed
  ];

  return (
    <div>
      <h1 className="insight-heading">Insights</h1>
      <div className="insight-section">
        {insights.map((insight) => (
          <div key={insight.id} className="insight-card">
            <div className="insight-image">
              <img src={insight.imageUrl} alt={`Insight ${insight.id}`} />
            </div>
            <div className="insight-content">
              <p className="insight-date">{insight.date}</p>
              <p className="insight-description">{insight.description}</p>
              <hr className="insight-divider" />
              <p className="insight-footer">
                {insight.para}

                <a href="#" className="read-more-link">
                  Read more
                </a>
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InsightSection;
